var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "video" } },
    [
      _c("b-embed", {
        attrs: {
          type: "iframe",
          aspect: _vm.getVideoRatio(),
          src: _vm.src,
          allowfullscreen: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }