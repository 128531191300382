var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "accordion",
      class: [
        _vm.asset.theme.skin_block_background_color
          ? "bg-" + _vm.asset.theme.skin_block_background_color
          : "bg-light",
        _vm.asset.theme.skin_block_text_color
          ? "text-" + _vm.asset.theme.skin_block_text_color
          : "text-dark",
      ],
      attrs: { id: "downloads", role: "tablist" },
    },
    [
      _vm.asset.downloadable === 1 || _vm.filteredFormats().length
        ? _c(
            "b-card",
            {
              staticClass: "mb-7",
              class: [
                _vm.asset.theme.skin_block_background_color
                  ? "bg-" + _vm.asset.theme.skin_block_background_color
                  : "bg-light",
                _vm.asset.theme.skin_block_text_color
                  ? "text-" + _vm.asset.theme.skin_block_text_color
                  : "text-dark",
              ],
              attrs: { "no-body": "" },
            },
            [
              _c(
                "b-card-header",
                { attrs: { "header-tag": "div", role: "tab" } },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c("b-col", { attrs: { cols: "auto" } }, [
                        _c("p", { staticClass: "m-0 lead" }, [
                          _vm._v(_vm._s(_vm.$t("downloads.title"))),
                        ]),
                      ]),
                      _vm.downloading
                        ? _c(
                            "b-col",
                            { staticClass: "ml-auto", attrs: { cols: "auto" } },
                            [
                              _c("p", { staticClass: "m-0 small" }, [
                                _vm._v(_vm._s(_vm.downloading + "%")),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-collapse",
                {
                  attrs: { id: "downloadsList", role: "tabpanel", visible: "" },
                },
                [
                  _vm.asset.downloadable
                    ? _c(
                        "b-list-group",
                        [
                          _vm.asset.downloadable === 1
                            ? _c(
                                "b-list-group-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFromUrl(
                                        _vm.asset.original_format.download_url,
                                        _vm.asset.original_format.format
                                          .filename
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    { attrs: { block: "", variant: "link" } },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "file-video"] },
                                      }),
                                      _c("span", { staticClass: "small" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.asset.original_format.format
                                              .filename
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "small" }, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.$t("downloads.original")
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filteredFormats().length
                    ? _c(
                        "b-list-group",
                        _vm._l(_vm.filteredFormats(), function (format, index) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.download(
                                    format.hash,
                                    "video",
                                    format.format.name +
                                      "." +
                                      format.format.container
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                { attrs: { block: "", variant: "link" } },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "mr-2",
                                    attrs: { icon: ["fas", "file-video"] },
                                  }),
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v(_vm._s(format.format.name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(format.format.container) +
                                        ")"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }