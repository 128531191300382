<template>
	<div id="stream">
		<b-container>
			<b-row>
				<b-col cols="12" sm="10" md="9" lg="7" class="text-left mw-40">
					<div class="block" :class="blockClasses()">
						<h4>{{ $t('stream.title') }}</h4>
						<p class="lead m-0 pt-3">{{ $t('stream.description') }}.</p>
						<b-form class="pt-5" @submit.prevent="submit">
							<b-form-group>
								<b-form-select :class="inputClasses()" v-model="selected" :options="options"></b-form-select>
							</b-form-group>
							<b-btn 	:variant="asset.theme.skin_button_color ? asset.theme.skin_button_color : 'primary'"
									:class="asset.theme.skin_button_text_color ? 'text-' + asset.theme.skin_button_text_color : null"
									:disabled="!selected"
									type="submit">
								{{ $t('form.confirm') }}
							</b-btn>
						</b-form>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				selected: null,
				options: null
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		methods: {
			submit() {
				this.$store.dispatch('setStream', this.selected)
			},
			blockClasses() {
				return [
					this.asset.theme.skin_block_background_color ? 'bg-'+this.asset.theme.skin_block_background_color : 'bg-light',
					this.asset.theme.skin_block_text_color ? 'text-'+this.asset.theme.skin_block_text_color : 'text-dark'
				]
			},
			inputClasses() {
				return [
					this.asset.theme.skin_input_background_color ? 'bg-'+this.asset.theme.skin_input_background_color : 'bg-white',
					this.asset.theme.skin_input_border_color ? 'border-'+this.asset.theme.skin_input_border_color : 'border-light',
					this.asset.theme.skin_input_text_color ? 'text-'+this.asset.theme.skin_input_text_color : 'text-dark'
				]
			}
		},
		mounted() {
			this.options = this.asset.stream_select.map(stream => {
				return {
					value: stream.id,
					text: stream.label ? stream.label : stream.name
				}
			})
			this.options.unshift({
				value: null,
				text: '--- ' + this.$t('form.select') + ' ---'
			})
		}
	}
</script>
