var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "live" } },
    [
      _c(
        "div",
        {
          class: [
            _vm.viewSize ? _vm.viewSize : "video",
            _vm.bigSlideRatio && _vm.asset.slideinstances.length
              ? "ratio-" + _vm.bigSlideRatio.aspect
              : "video",
            _vm.asset.theme.use_alternate_background_color
              ? "bg-background"
              : null,
            _vm.asset.theme.use_alternate_background_color &&
            !_vm.asset.slideinstances.length
              ? "pb-7 pb-md-8"
              : null,
          ],
          attrs: { id: "media" },
        },
        [
          _c("b-container", [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("Player"),
                _vm.asset.slideinstances.length ? _c("BigSlide") : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.asset.slideinstances.length ? _c("Controllers") : _vm._e(),
      _vm.asset.slideinstances.length
        ? _c("Slides", { attrs: { live: true } })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "pt-7 pt-lg-10 pb-7",
          class: _vm.asset.theme.use_alternate_background_color ? "bg-alt" : "",
          attrs: { id: "info" },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pb-7", attrs: { cols: "12", lg: "5" } },
                    [
                      _c("h5", {
                        domProps: {
                          innerHTML: _vm._s(_vm.makeBr(_vm.asset.title)),
                        },
                      }),
                      _vm.asset.description
                        ? _c("p", {
                            staticClass: "lead m-0 pt-3",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.makeBr(_vm.asset.description)
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm.asset.tags && _vm.asset.tags.length
                        ? _c(
                            "div",
                            { staticClass: "pt-3", attrs: { id: "tags" } },
                            _vm._l(_vm.asset.tags, function (tag, key) {
                              return _c(
                                "b-badge",
                                { key: key, staticClass: "mr-1" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(tag.name ? tag.name : tag) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "ml-auto", attrs: { cols: "12", lg: "6" } },
                    [_c("Talkers")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.asset.asset_qa && _vm.asset.asset_qa.enabled
        ? _c("AskQuestion")
        : _vm._e(),
      _vm.asset.breakouts && _vm.asset.breakouts.length
        ? _c("Breakouts")
        : _vm._e(),
      _c("Chat"),
      _c("Poll"),
      _vm.asset.slideinstances.length && _vm.viewSlide
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "slideModal",
                size: "xl",
                show: "",
                "hide-header": "",
                "hide-footer": "",
                centered: "",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.zoomOutSlide.apply(null, arguments)
                },
              },
            },
            [
              _c("b-img", {
                attrs: { src: _vm.slideUrl(_vm.viewSlide), fluid: "" },
                on: { click: _vm.zoomOutSlide },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }