<template>
	<section id="category" class="align-center">
        <b-container class="py-5 text-center">
            <h1>Category page coming soon...</h1>
        </b-container>
	</section>
</template>
<script>
    export default {
		name: 'Category',
		computed: {
			category() {
				return this.$store.getters.category
			}
		}
    }
</script>
