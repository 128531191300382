<template>
	<section 	id="preview"
				class="d-flex"
				:style="backgroundImage ? backgroundImage : null"
				v-if="asset">
		<div :class="channel.skin_background_header === 'transparent' || channel.skin_background_header === channel.background_color ? 'pt-md-3' : 'pt-4 pt-md-9'">
			<video class="bg-video" autoplay loop muted playsinline webkit-playsinline v-if="backgroundVideo">
				<source :src="backgroundVideo" :type="genFileType">
			</video>
			<Live />
		</div>
	</section>
</template>
<script>
	import Live from '@/components/asset/Live.vue'
	export default {
		name: 'Preview',
		components: {
			Live
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			channel() {
				return this.$store.getters.channel
			},
			backgroundImage() {
				const background = this.asset.background && this.asset.background.type === "image" && this.asset.background.url
				if(background) return 'background-image: url('+this.asset.background.url+')'
				if(this.asset.poster) return 'background-image: url('+this.asset.poster+')'
				return null
			},
			backgroundVideo() {
				const background = this.asset.background && this.asset.background.type === "video" && this.asset.background.url
				if(background) return this.asset.background.url
				return null
			},
			genFileType() {
				const ext = this.asset.background ? this.asset.background.url.split('.').pop() : null
				if(ext) return this.asset.background.type + '/' + ext
				return null
			}
		}
	}
</script>
