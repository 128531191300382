var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        {
          staticClass: "text-center px-1 mx-auto",
          attrs: { cols: "6", sm: "4" },
        },
        [
          _c("div", { staticClass: "px-4" }, [
            _c("img", {
              staticClass: "img-thumbnail img-fluid rounded-circle",
              attrs: {
                src: require("@/assets/img/patrik.png"),
                alt: "Patrik Blomqvist",
              },
            }),
          ]),
          _c("p", { staticClass: "small m-0 pt-3" }, [
            _vm._v("Patrik Blomqvist"),
          ]),
          _c("p", { staticClass: "small m-0" }, [_vm._v("Business Developer")]),
          _c("p", { staticClass: "small m-0" }, [_vm._v("Creo")]),
        ]
      ),
      _c(
        "b-col",
        {
          staticClass: "text-center px-1 mx-auto",
          attrs: { cols: "6", sm: "4" },
        },
        [
          _c("div", { staticClass: "px-4" }, [
            _c("img", {
              staticClass: "img-thumbnail img-fluid rounded-circle",
              attrs: {
                src: require("@/assets/img/nicklas.png"),
                alt: "Nicklas Mattsson",
              },
            }),
          ]),
          _c("p", { staticClass: "small m-0 pt-3" }, [
            _vm._v("Nicklas Mattsson"),
          ]),
          _c("p", { staticClass: "small m-0" }, [_vm._v("Head of Newsroom")]),
          _c("p", { staticClass: "small m-0" }, [_vm._v("SEB")]),
        ]
      ),
      _c(
        "b-col",
        {
          staticClass: "text-center px-1 mx-auto",
          attrs: { cols: "6", sm: "4" },
        },
        [
          _c("div", { staticClass: "px-4" }, [
            _c("img", {
              staticClass: "img-thumbnail img-fluid rounded-circle",
              attrs: {
                src: require("@/assets/img/magnus.png"),
                alt: "Magnus Haglund-Lindner",
              },
            }),
          ]),
          _c("p", { staticClass: "small m-0 pt-3" }, [
            _vm._v("Magnus Haglund-Lindner"),
          ]),
          _c("p", { staticClass: "small m-0" }, [
            _vm._v("Exekutiv producent & Teamchef studioproduktion"),
          ]),
          _c("p", { staticClass: "small m-0" }, [_vm._v("Unionen")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }