var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar",
      class: _vm.sidebar === "chat" ? "visible" : null,
      attrs: { id: "chat" },
    },
    [_c("iframe", { attrs: { src: _vm.asset.chat_url } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }