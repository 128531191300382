<template>
	<div id="live">
		<div 	id="media"
				:class="[
					viewSize ? viewSize : 'video',
					bigSlideRatio && asset.slideinstances.length ? 'ratio-'+bigSlideRatio.aspect : 'video',
					asset.theme.use_alternate_background_color ? 'bg-background' : null,
					asset.theme.use_alternate_background_color && !asset.slideinstances.length ? 'pb-7 pb-md-8' : null
				]">
			<b-container>
				<div class="content">
					<Player />
					<BigSlide v-if="asset.slideinstances.length" />
				</div>
			</b-container>
		</div>
		<Controllers v-if="asset.slideinstances.length" />
		<Slides :live="true" v-if="asset.slideinstances.length" />
		<div id="info" class="pt-7 pt-lg-10 pb-7" :class="asset.theme.use_alternate_background_color ? 'bg-alt' : ''">
			<b-container>
				<b-row>
					<b-col cols="12" lg="5" class="pb-7">
						<h5 v-html="makeBr(asset.title)"></h5>
						<p class="lead m-0 pt-3" v-html="makeBr(asset.description)" v-if="asset.description"></p>
						<div id="tags" class="pt-3" v-if="asset.tags && asset.tags.length">
							<b-badge 	class="mr-1"
										v-for="(tag, key) in asset.tags"
										:key="key">
								{{ tag.name ? tag.name : tag }}
							</b-badge>
						</div>
					</b-col>
					<b-col cols="12" lg="6" class="ml-auto">
						<Talkers></Talkers>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<AskQuestion v-if="asset.asset_qa && asset.asset_qa.enabled"></AskQuestion>
		<Breakouts v-if="asset.breakouts && asset.breakouts.length"></Breakouts>
		<Chat></Chat>
		<Poll></Poll>
		<b-modal 	id="slideModal"
					size="xl"
					show
					hide-header
					hide-footer
					centered
					@click.prevent="zoomOutSlide"
					v-if="asset.slideinstances.length && viewSlide">
			<b-img :src="slideUrl(viewSlide)" fluid @click="zoomOutSlide"></b-img>
		</b-modal>
	</div>
</template>
<script>
	import Player from '@/components/asset/Player.vue'
	import BigSlide from '@/components/asset/BigSlide.vue'
	import Controllers from '@/components/asset/Controllers.vue'
	import Slides from '@/components/asset/Slides.vue'
	import AskQuestion from '@/components/asset/AskQuestion.vue'
	import Breakouts from '@/components/asset/Breakouts.vue'
	import Chat from '@/components/asset/Chat.vue'
	import Poll from '@/components/asset/Poll.vue'
	import Talkers from '@/components/asset/Talkers.vue'
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			bigSlideRatio() {
				return this.$store.getters.bigSlideRatio
			},
			channel() {
				return this.$store.getters.channel
			},
			viewSize() {
				return this.$store.getters.viewSize
			},
			viewSlide() {
				return this.$store.getters.viewSlide
			},
			sidebar() {
				return this.$store.getters.sidebar
			}
		},
		components: {
			Player,
			BigSlide,
			Controllers,
			Slides,
			AskQuestion,
			Breakouts,
			Chat,
			Poll,
			Talkers
		},
		methods: {
			slideUrl(slide) {
				return this.asset.slide_path + '1920/' + slide.path;
			},
			zoomOutSlide() {
				this.$root.$emit('bv::hide::modal', 'slideModal')
			},
			makeBr(text) {
				return text.replace(/\n/g, '<br>')
			}
		}
	}
</script>
