import { render, staticRenderFns } from "./PostLive.vue?vue&type=template&id=4b072aca&"
import script from "./PostLive.vue?vue&type=script&lang=js&"
export * from "./PostLive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Library/WebServer/cvm3-channels/creo-talks/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b072aca')) {
      api.createRecord('4b072aca', component.options)
    } else {
      api.reload('4b072aca', component.options)
    }
    module.hot.accept("./PostLive.vue?vue&type=template&id=4b072aca&", function () {
      api.rerender('4b072aca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/asset/PostLive.vue"
export default component.exports