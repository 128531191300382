var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stream" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-left mw-40",
                  attrs: { cols: "12", sm: "10", md: "9", lg: "7" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "block", class: _vm.blockClasses() },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("stream.title")))]),
                      _c("p", { staticClass: "lead m-0 pt-3" }, [
                        _vm._v(_vm._s(_vm.$t("stream.description")) + "."),
                      ]),
                      _c(
                        "b-form",
                        {
                          staticClass: "pt-5",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("b-form-select", {
                                class: _vm.inputClasses(),
                                attrs: { options: _vm.options },
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-btn",
                            {
                              class: _vm.asset.theme.skin_button_text_color
                                ? "text-" +
                                  _vm.asset.theme.skin_button_text_color
                                : null,
                              attrs: {
                                variant: _vm.asset.theme.skin_button_color
                                  ? _vm.asset.theme.skin_button_color
                                  : "primary",
                                disabled: !_vm.selected,
                                type: "submit",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("form.confirm")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }