<template>
	<div id="downloads" class="accordion" :class="[asset.theme.skin_block_background_color ? 'bg-'+asset.theme.skin_block_background_color : 'bg-light', asset.theme.skin_block_text_color ? 'text-'+asset.theme.skin_block_text_color : 'text-dark']" role="tablist">
		<b-card class="mb-7" no-body v-if="asset.downloadable === 1 || filteredFormats().length" :class="[asset.theme.skin_block_background_color ? 'bg-'+asset.theme.skin_block_background_color : 'bg-light', asset.theme.skin_block_text_color ? 'text-'+asset.theme.skin_block_text_color : 'text-dark']">
			<b-card-header header-tag="div" role="tab" >
				<b-row align-v="center">
					<b-col cols="auto">
						<p class="m-0 lead">{{ $t('downloads.title') }}</p>
					</b-col>
					<b-col cols="auto" class="ml-auto" v-if="downloading">
						<p class="m-0 small">{{ downloading+'%' }}</p>
					</b-col>
				</b-row>
			</b-card-header>
			<b-collapse id="downloadsList" role="tabpanel" visible>
				<b-list-group v-if="asset.downloadable">
					<b-list-group-item 	@click="downloadFromUrl(asset.original_format.download_url, asset.original_format.format.filename)"
										v-if="asset.downloadable === 1">
						<b-btn block variant="link">
							<font-awesome-icon :icon="['fas', 'file-video']" />
							<span class="small">{{ asset.original_format.format.filename }}</span> <span class="small">({{ $t('downloads.original') }})</span>
						</b-btn>
					</b-list-group-item>
				</b-list-group>
				<b-list-group v-if="filteredFormats().length">
					<b-list-group-item 	@click="download(format.hash, 'video', format.format.name + '.' + format.format.container)"
										:key="index"
										v-for="(format, index) in filteredFormats()">
						<b-btn block variant="link">
							<font-awesome-icon :icon="['fas', 'file-video']" class="mr-2"/>
							<span class="small">{{ format.format.name }}</span> <span class="small">({{ format.format.container }})</span>
						</b-btn>
					</b-list-group-item>
				</b-list-group>
			</b-collapse>
		</b-card>
	</div>
</template>
<script>
	const Color = require('color')
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			downloading() {
				return this.$store.getters.downloading
			}
		},
		methods: {
			filteredFormats() {
				return this.asset.asset_format.filter(f => f.format.type == 'download')
			},
			download(hash, type, name) {
				this.$store.dispatch('download', { slug: this.asset.asset_category_key, hash: hash, type: type, token: this.$route.query.token, password: this.$route.query.password })
				.then(response => {
					this.downloadFromUrl(response.data.url, name)
				})
				.catch(() => {
					this.$toasted.error(this.$t('downloads.error'), { position: 'bottom-right' })
				})
			},
			downloadFromUrl(url, name) {
				this.$store.dispatch('downloadFromUrl', { url: url })
				.then(response => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', name)
					link.click()
				})
				.catch(() => {
					this.$toasted.error(this.$t('downloads.error'), { position: 'bottom-right' })
				})
			},
			getFileIcon(type) {
				if (type === 2) return 'file-image'
				if (type === 3) return 'file-pdf' 
				if (type === 5) return 'file-powerpoint'
				if (type === 6) return 'file-archive'
				if (type === 7) return 'file-audio'
				return 'file'
			},
			isDark() {
				return Color('#'+this.asset.theme.skin_color_text).isDark()
			}
		}
	}
</script>
