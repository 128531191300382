var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.asset
    ? _c(
        "section",
        {
          staticClass: "d-flex",
          class: _vm.backgroundVideo || _vm.backgroundImage ? "bg-cover" : null,
          style: _vm.backgroundImage ? _vm.backgroundImage : null,
          attrs: { id: "questions" },
        },
        [
          _vm.backgroundVideo
            ? _c(
                "video",
                {
                  staticClass: "bg-video",
                  attrs: {
                    autoplay: "",
                    loop: "",
                    muted: "",
                    playsinline: "",
                    "webkit-playsinline": "",
                  },
                  domProps: { muted: true },
                },
                [
                  _c("source", {
                    attrs: { src: _vm.backgroundVideo, type: _vm.genFileType },
                  }),
                ]
              )
            : _vm._e(),
          _vm.getPassword() && _vm.questionType === "moderator"
            ? _c("Moderator")
            : _vm.getPassword() && _vm.questionType
            ? _c("Presenter")
            : _vm.getPassword()
            ? _c("Choose")
            : _c("Login", { attrs: { error: _vm.error } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }