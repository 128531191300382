var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "choosePage" } },
    [
      _c(
        "b-container",
        { staticClass: "p-2 p-md-3" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "pr-xl-10 pb-7 pb-lg-0",
                  attrs: { cols: "12", lg: "5" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "block bg-dark-transparent",
                      class: _vm.blockClasses(),
                    },
                    [
                      _c("h5", { staticClass: "mb-3" }, [
                        _vm._v(_vm._s(_vm.$t("questions.title"))),
                      ]),
                      _c("p", { staticClass: "lead m-0" }, [
                        _vm._v(_vm._s(_vm.$t("questions.text")) + "."),
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "pt-5" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "pr-0", attrs: { cols: "auto" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant:
                                      _vm.asset.theme
                                        .skin_block_background_color ===
                                        "dark" ||
                                      _vm.asset.theme
                                        .skin_block_background_color === "black"
                                        ? "light"
                                        : "dark",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseType("presenter")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("questions.presenter")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  class: _vm.asset.theme.skin_button_text_color
                                    ? "text-" +
                                      _vm.asset.theme.skin_button_text_color
                                    : null,
                                  attrs: {
                                    variant: _vm.asset.theme.skin_button_color
                                      ? _vm.asset.theme.skin_button_color
                                      : "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseType("moderator")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("questions.editor")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }