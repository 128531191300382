var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notfound py-4" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "text-center" }, [
            _c("h1", [_vm._v(_vm._s(_vm.$t("notfound.title")) + "...")]),
            _c("p", [_vm._v(_vm._s(_vm.$t("notfound.text")) + ".")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }