var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "postlive" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "pr-xl-10 pb-7 pb-lg-0",
                  attrs: { cols: "12", lg: "5" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "block bg-dark-transparent",
                      class: _vm.blockClasses(),
                    },
                    [
                      _c("h5", { staticClass: "mb-3" }, [
                        _vm._v(_vm._s(_vm.$t("stream.ended"))),
                      ]),
                      _vm.asset.postlive
                        ? _c("p", {
                            staticClass: "lead m-0 pt-3",
                            domProps: {
                              innerHTML: _vm._s(_vm.makeBr(_vm.asset.postlive)),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm.postliveQa()
                    ? _c("ask-question", { attrs: { live: false } })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mx-auto",
                  attrs: { cols: "12", lg: "6", xl: "5" },
                },
                [
                  _vm.asset.title
                    ? _c("h5", {
                        staticClass: "mb-3 pb-3",
                        domProps: {
                          innerHTML: _vm._s(_vm.makeBr(_vm.asset.title)),
                        },
                      })
                    : _vm._e(),
                  _vm.asset.description
                    ? _c("p", {
                        staticClass: "lead m-0 text-light",
                        domProps: {
                          innerHTML: _vm._s(_vm.makeBr(_vm.asset.description)),
                        },
                      })
                    : _vm._e(),
                  _c("Talkers", { staticClass: "pt-8" }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }