<template>
	<section 	id="asset"
				class="d-flex"
				:class="!this.isStreamSelected || this.isPrePostLive ? 'justify-content-center bg-cover' : null"
				:style="backgroundImage && !this.isStreamSelected || backgroundImage && this.isPrePostLive ? backgroundImage : null"
				v-if="asset">
		<div :class="channel.skin_background_header === 'transparent' || channel.skin_background_header === channel.background_color ? 'pt-md-3' : 'pt-4 pt-md-9'">
			<video class="bg-video" autoplay loop muted playsinline webkit-playsinline v-if="backgroundVideo">
				<source :src="backgroundVideo" :type="genFileType">
			</video>
			<Stream v-if="[1].includes(asset.broadcasting) && !isStreamSelected" />
			<PreLive v-else-if="[0].includes(asset.broadcasting)" />
			<Live v-else-if="[1].includes(asset.broadcasting)" />
			<PostLive v-else-if="[2,3].includes(asset.broadcasting) && asset.status_id !== 5" />
			<OnDemand v-else />
		</div>
	</section>
</template>
<script>
	import Stream from '@/components/asset/Stream.vue'
	import PreLive from '@/components/asset/PreLive.vue'
	import Live from '@/components/asset/Live.vue'
	import PostLive from '@/components/asset/PostLive.vue'
	import OnDemand from '@/components/asset/OnDemand.vue'
	export default {
		name: 'Asset',
		components: {
			Stream,
			PreLive,
			Live,
			PostLive,
			OnDemand
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			backgroundImage() {
				const background = this.asset.background && this.asset.background.type === "image" && this.asset.background.url
				if(background) return 'background-image: url('+this.asset.background.url+')'
				if(this.asset.poster) return 'background-image: url('+this.asset.poster+')'
				return null
			},
			backgroundVideo() {
				const background = this.asset.background && this.asset.background.type === "video" && this.asset.background.url
				if(background) return this.asset.background.url
				return null
			},
			channel() {
				return this.$store.getters.channel
			},
			genFileType() {
				const ext = this.asset.background ? this.asset.background.url.split('.').pop() : null
				if(ext) return this.asset.background.type + '/' + ext
				return null
			},
			isStreamSelected() {
				return this.asset.stream_select && !this.$store.getters.stream ? false : true
			},
			isPrePostLive() {
				return ([0,2,3].includes(this.asset.broadcasting) && this.asset.status_id !== 5) ? true : false
			}
		}
	}
</script>
