<template>
	<div id="presenter" v-if="questions">
		<b-container class="p-0">
			<div class="table-container px-2 px-md-3">
				<b-table-simple small responsive="sm">
					<b-tbody>
						<Draggable 	v-model="questions"
									draggable=".item"
									handle=".order"
									@update="changeOrder">
							<b-tr class="item" v-for="item in questions" :key="item.id">
								<b-td class="col-auto text-dark bg-gray-lighter px-3 d-none d-md-table-cell">
									<p class="m-0 number">{{ item.id }}</p>
								</b-td>
								<b-td class="col">
									<div class="text pl-4" @click="showMore !== item.id ? showMore = item.id : showMore = null">
										<p class="m-0">{{ item.text }}</p>
										<div v-if="showMore === item.id">
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.name && item.name">
												<font-awesome-icon :icon="['fas', 'user']" fixed-width class="mr-1" /> {{ item.name }}
											</p>
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.title && item.title">
												<font-awesome-icon :icon="['fas', 'pen']" fixed-width class="mr-1" /> {{ item.title }}
											</p>
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.phone && item.phone">
												<font-awesome-icon :icon="['fas', 'phone']" fixed-width class="mr-1" /> {{ item.phone }}
											</p>
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.company && item.company">
												<font-awesome-icon :icon="['fas', 'building']" fixed-width class="mr-1" /> {{ item.company }}
											</p>
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.email && item.email">
												<font-awesome-icon :icon="['fas', 'envelope']" fixed-width class="mr-1" /> {{ item.email }}
											</p>
											<p class="m-0 pt-2 small font-italic">
												<font-awesome-icon :icon="['fas', 'calendar']" fixed-width class="mr-1" /> {{ humanizeDate(item.created_at) }}
											</p>
										</div>
										<p class="m-0 pt-2 small font-italic" v-else-if="item.email">{{ item.email }}</p>
									</div>
								</b-td>
								<b-td class="col-auto">
									<b-btn variant="link" class="hover-success" @click="editQuestion('answered', item)">
										<font-awesome-icon :icon="['fas', 'spinner']" spin v-if="loading && loading.type === 'answered' && loading.id === item.id" />
										<font-awesome-icon :icon="['fas', item.answered ? 'times' : 'check']" v-else />
									</b-btn>
								</b-td>
								<b-td class="col-auto text-dark bg-gray-lighter px-4 order">
									<p class="m-0"><font-awesome-icon :icon="['fas', 'bars']" /></p>
								</b-td>
							</b-tr>
						</Draggable>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-container>
	</div>
	<Loading :inline="true" v-else />
</template>
<script>
	import Draggable from 'vuedraggable'
	import Loading from '@/components/Loading.vue'
	const moment = require('moment-timezone')
	export default {
		components: {
			Draggable,
			Loading
		},
		data() {
			return {
				loading: null,
				showMore: null
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			questions: {
				get() {
					if (!this.$store.getters.questions) return null
					return this.$store.getters.questions.presenter
				},
				set() {}
			},
			questionPassword() {
				if (this.$store.getters.questionPassword) return this.$store.getters.questionPassword
				if (sessionStorage.getItem('questionPassword')) return sessionStorage.getItem('questionPassword')
				if (this.$route.query.moderator) return this.$route.query.moderator
				return null
			}
		},
		methods: {
			getSSOToken() {
				return localStorage.getItem('sso_token') ? localStorage.getItem('sso_token') : this.$route.query.sso_token
			},
			getSSOType() {
				return localStorage.getItem('sso_type') ? localStorage.getItem('sso_type') : this.$route.query.sso_type
			},
			editQuestion(field, item) {
				this.loading = { id: item.id, type: field }
				if(field) {
					item[field] = !item[field]
					if(field === 'rejected') {
						item['answered'] = false
					} else {
						item['rejected'] = false
					}
				}
				const data = {
					slug: this.asset.asset_category_key,
					id: item.id,
					moderator_password: this.questionPassword,
					answered: item.answered,
					order: item.order,
					rejected: item.rejected,
					sso_token: this.getSSOToken(),
					sso_type: this.getSSOType(),
					token: this.$route.query.token,
					password: this.$route.query.password,
				}
				this.$store.dispatch('updateQuestion', data)
				.catch(() => {
					this.$toasted.error(field.answered ? this.$t('questions.error-answer') : this.$t('questions.error-reject'), { position: 'bottom-right' })
				})
				.finally(() => {
					this.loading = null
				})
			},
			humanizeDate(date) {
				const utcDate = this.$moment(date).parseZone()
				const tz = moment.tz.guess(true)
				const tzDate = this.$moment(utcDate).tz(tz).format()
				return this.$moment(tzDate).calendar(null, {
					lastDay:  '['+this.$t('questions.yesterday')+']' +' '+ 'HH:mm',
					sameDay:  'HH:mm',
					lastWeek : 'Do MMM HH:mm',
					nextWeek : 'Do MMM HH:mm',
					sameElse: function() {
						return 'Do MMM HH:mm'
					}
				})
			},
			changeOrder(e) {
				let item = e.item._underlying_vm_
					item.order = e.newIndex
				this.editQuestion(null, item)
			}
		}
	}
</script>
