<template>
	<div id="prelive">
		<b-container>
			<b-row align-v="center">
				<b-col cols="12" lg="5" class="pr-xl-10 pb-7 pb-lg-0">
					<div class="block bg-dark-transparent" :class="blockClasses()">
						<h5 class="mb-3">{{ $t('common.registered') }}</h5>
						<p class="lead m-0 pt-3" v-if="asset.is_countdown && starting">{{ $t('countdown.event') }}...</p>
						<p class="lead m-0 pt-3" v-html="makeBr(asset.prelive)" v-else-if="asset.prelive"></p>
						<p class="lead m-0 pt-3">
							<a 	:href="asset.calender_url"
								target="_blank"
								class="small text-inherit"
								:title="$t('common.add-reminder')">
								{{ $t('common.add-reminder') }}
							</a>
						</p>
						<countdown @cdStarting="cdStarts" class="pt-5 pt-lg-7" v-if="asset.is_countdown"></countdown>
					</div>
					<ask-question :live="false" v-if="preliveQa()" />
				</b-col>
				<b-col cols="12" lg="6" xl="5" class="mx-auto">
					<h5 class="mb-3 pb-3" v-html="makeBr(asset.title)" v-if="asset.title" />
					<p class="lead m-0 text-light" v-html="makeBr(asset.description)" v-if="asset.description"></p>
					<Talkers class="pt-8" />
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	import Countdown from '@/components/asset/Countdown.vue'
	import AskQuestion from '@/components/asset/AskQuestion.vue'
	import Talkers from '@/components/asset/Talkers.vue'
	export default {
		data() {
			return {
				starting: false
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		components: {
			Countdown,
			AskQuestion,
			Talkers
		},
		methods: {
			preliveQa() {
				if(this.asset.asset_qa && this.asset.asset_qa.enabled && this.asset.asset_qa.prelive) return true
				return false
			},
			makeBr(text) {
				return text.replace(/\n/g, '<br>')
			},
			cdStarts(val) {
				this.starting = val
			},
			blockClasses() {
				return [
					this.asset.text_color ? 'text-'+this.asset.text_color : 'text-light'
				]
			}
		}
	}
</script>
