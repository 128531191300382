var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "askQuestion" } },
    [
      _c("b-container", { class: _vm.live ? "pb-7" : "px-0 pt-3" }, [
        _c(
          "div",
          {
            staticClass: "bg-dark-transparent text-light p-7 p-lg-9 rounded-20",
          },
          [
            _c("h5", [_vm._v(_vm._s(_vm.$t("common.ask-question")))]),
            _c(
              "b-form",
              {
                staticClass: "pt-5",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.sendQuestion.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        attrs: {
                          cols: "12",
                          md: _vm.inputCounter > 1 && _vm.live ? "6" : null,
                        },
                      },
                      [
                        _vm.asset.asset_qa.name
                          ? _c(
                              "b-form-group",
                              {
                                staticClass: "mb-4",
                                class: _vm.questionForm.name ? "filled" : null,
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "questionName" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("form.name") +
                                          (_vm.asset.asset_qa.name === 2
                                            ? " *"
                                            : "")
                                      )
                                    ),
                                  ]
                                ),
                                _c("b-form-input", {
                                  class: _vm.inputClasses(),
                                  attrs: {
                                    id: "questionName",
                                    type: "text",
                                    name: "name",
                                    disabled: _vm.loading,
                                    required:
                                      _vm.asset.asset_qa.name === 2
                                        ? true
                                        : false,
                                  },
                                  model: {
                                    value: _vm.questionForm.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.questionForm, "name", $$v)
                                    },
                                    expression: "questionForm.name",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.asset.asset_qa.title
                          ? _c(
                              "b-form-group",
                              {
                                staticClass: "mb-4",
                                class: _vm.questionForm.title ? "filled" : null,
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "questionTitle" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("form.title") +
                                          (_vm.asset.asset_qa.title === 2
                                            ? " *"
                                            : "")
                                      )
                                    ),
                                  ]
                                ),
                                _c("b-form-input", {
                                  class: _vm.inputClasses(),
                                  attrs: {
                                    id: "questionTitle",
                                    type: "text",
                                    name: "title",
                                    disabled: _vm.loading,
                                    required:
                                      _vm.asset.asset_qa.title === 2
                                        ? true
                                        : false,
                                  },
                                  model: {
                                    value: _vm.questionForm.title,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.questionForm, "title", $$v)
                                    },
                                    expression: "questionForm.title",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.asset.asset_qa.phone
                          ? _c(
                              "b-form-group",
                              {
                                staticClass: "mb-4",
                                class: _vm.questionForm.phone ? "filled" : null,
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "questionPhone" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("form.phone") +
                                          (_vm.asset.asset_qa.phone === 2
                                            ? " *"
                                            : "")
                                      )
                                    ),
                                  ]
                                ),
                                _c("b-form-input", {
                                  class: _vm.inputClasses(),
                                  attrs: {
                                    id: "questionPhone",
                                    type: "tel",
                                    name: "phone",
                                    disabled: _vm.loading,
                                    required:
                                      _vm.asset.asset_qa.phone === 2
                                        ? true
                                        : false,
                                  },
                                  model: {
                                    value: _vm.questionForm.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.questionForm, "phone", $$v)
                                    },
                                    expression: "questionForm.phone",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.asset.asset_qa.company
                          ? _c(
                              "b-form-group",
                              {
                                staticClass: "mb-4",
                                class: _vm.questionForm.company
                                  ? "filled"
                                  : null,
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "questionCompany" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("form.company") +
                                          (_vm.asset.asset_qa.company === 2
                                            ? " *"
                                            : "")
                                      )
                                    ),
                                  ]
                                ),
                                _c("b-form-input", {
                                  class: _vm.inputClasses(),
                                  attrs: {
                                    id: "questionCompany",
                                    type: "text",
                                    name: "company",
                                    disabled: _vm.loading,
                                    required:
                                      _vm.asset.asset_qa.company === 2
                                        ? true
                                        : false,
                                  },
                                  model: {
                                    value: _vm.questionForm.company,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.questionForm, "company", $$v)
                                    },
                                    expression: "questionForm.company",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.asset.asset_qa.email
                          ? _c(
                              "b-form-group",
                              {
                                staticClass: "mb-4",
                                class: _vm.questionForm.email ? "filled" : null,
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "questionEmail" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("form.email") +
                                          (_vm.asset.asset_qa.email === 2
                                            ? " *"
                                            : "")
                                      )
                                    ),
                                  ]
                                ),
                                _c("b-form-input", {
                                  class: _vm.inputClasses(),
                                  attrs: {
                                    id: "questionEmail",
                                    type: "email",
                                    name: "email",
                                    disabled: _vm.loading,
                                    required:
                                      _vm.asset.asset_qa.email === 2
                                        ? true
                                        : false,
                                  },
                                  model: {
                                    value: _vm.questionForm.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.questionForm, "email", $$v)
                                    },
                                    expression: "questionForm.email",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        attrs: {
                          cols: "12",
                          md: _vm.inputCounter > 1 && _vm.live ? "6" : null,
                        },
                      },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "textarea mb-4",
                            class: _vm.questionForm.text ? "filled" : null,
                          },
                          [
                            _c("label", { attrs: { for: "questionText" } }, [
                              _vm._v(_vm._s(_vm.$t("form.question") + " *")),
                            ]),
                            _c("b-form-textarea", {
                              class: _vm.inputClasses(),
                              attrs: {
                                id: "questionText",
                                rows: "3",
                                disabled: _vm.loading,
                                required: "",
                              },
                              model: {
                                value: _vm.questionForm.text,
                                callback: function ($$v) {
                                  _vm.$set(_vm.questionForm, "text", $$v)
                                },
                                expression: "questionForm.text",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _vm.asset.asset_qa.gdpr
                      ? _c(
                          "b-col",
                          { staticClass: "pt-1", attrs: { cols: "auto" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    class: _vm.checkClasses(),
                                    attrs: {
                                      id: "questionGdpr",
                                      name: "questionGdpr",
                                      disabled: _vm.loading,
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.questionForm.gdpr,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.questionForm, "gdpr", $$v)
                                      },
                                      expression: "questionForm.gdpr",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "small",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.asset.asset_qa.gdpr + " *"
                                        ),
                                      },
                                    }),
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "check", size: "sm" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      {
                        staticClass: "text-md-right ml-auto pt-1",
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c("p", { staticClass: "smaller" }, [
                          _vm._v("* " + _vm._s(_vm.$t("form.mandatory"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.success || _vm.error
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "pb-3", attrs: { cols: "12" } },
                          [
                            _vm.success
                              ? _c(
                                  "b-alert",
                                  {
                                    attrs: {
                                      variant: "success",
                                      show: 5,
                                      fade: "",
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "m-0 small" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("form.success")) + "."
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.error
                              ? _c(
                                  "b-alert",
                                  {
                                    attrs: {
                                      variant: "danger",
                                      show: 5,
                                      fade: "",
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "m-0 small" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("form.error")) + "."
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  { class: _vm.live ? "pt-3" : null },
                  [
                    _vm.loading
                      ? _c(
                          "b-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: ["fas", "spinner"],
                                spin: true,
                                size: "lg",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "b-col",
                          {
                            class: _vm.live ? "text-center" : "text-right",
                            attrs: { cols: "12" },
                          },
                          [
                            _c(
                              "b-btn",
                              {
                                class: _vm.asset.theme.skin_button_text_color
                                  ? "text-" +
                                    _vm.asset.theme.skin_button_text_color
                                  : null,
                                attrs: {
                                  type: "submit",
                                  variant: _vm.asset.theme.skin_button_color
                                    ? _vm.asset.theme.skin_button_color
                                    : "primary",
                                  disabled: _vm.loading,
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("form.send")))]
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }