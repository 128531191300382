<template>
	<div id="ondemand">
		<div 	id="media"
				:class="[
					viewSize ? viewSize : 'video',
					bigSlideRatio && asset.slideinstances.length ? 'ratio-'+bigSlideRatio.aspect : 'video',
					asset.theme.use_alternate_background_color ? 'bg-background' : null,
					asset.theme.use_alternate_background_color && !asset.slideinstances.length ? 'pb-7 pb-md-8' : null
				]">
			<b-container>
				<div class="content">
					<Player />
					<BigSlide v-if="asset.slideinstances.length" />
				</div>
			</b-container>
		</div>
		<Controllers v-if="asset.slideinstances.length" />
		<Slides v-if="asset.slideinstances.length" />
		<div id="info" class="py-7 py-md-8">
			<b-container>
				<b-row>
					<b-col cols="12" :md="asset.chapters && asset.chapters.length || asset.downloadable === 1 || filteredFormats().length ? '8' : '12'" class="pb-7 pb-md-0">
						<h2 v-html="makeBr(asset.title)"></h2>
						<p class="lead m-0 pt-3" v-html="makeBr(asset.description)" v-if="asset.description"></p>
						<div id="tags" class="pt-3" v-if="asset.tags && asset.tags.length">
							<b-badge 	class="mr-1"
										v-for="(tag, key) in asset.tags"
										:key="key">
								{{ tag.name ? tag.name : tag }}
							</b-badge>
						</div>
					</b-col>
					<b-col cols="12" md="4" v-if="asset.chapters && asset.chapters.length || asset.downloadable === 1 || filteredFormats().length">
						<Downloads v-if="asset.downloadable === 1 || filteredFormats().length" />
						<Chapters v-if="asset.chapters && asset.chapters.length" />
					</b-col>
				</b-row>
			</b-container>
		</div>
		<b-modal 	id="slideModal"
					size="xl"
					show
					hide-header
					hide-footer
					centered
					@click.prevent="zoomOutSlide"
					v-if="asset.slideinstances.length && viewSlide">
			<b-img :src="slideUrl(viewSlide)" fluid @click="zoomOutSlide"></b-img>
		</b-modal>
	</div>
</template>
<script>
	import Player from '@/components/asset/Player.vue'
	import BigSlide from '@/components/asset/BigSlide.vue'
	import Controllers from '@/components/asset/Controllers.vue'
	import Slides from '@/components/asset/Slides.vue'
	import Downloads from '@/components/asset/Downloads.vue'
	import Chapters from '@/components/asset/Chapters.vue'
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			bigSlideRatio() {
				return this.$store.getters.bigSlideRatio
			},
			viewSize() {
				return this.$store.getters.viewSize
			},
			viewSlide() {
				return this.$store.getters.viewSlide
			}
		},
		components: {
			Player,
			BigSlide,
			Controllers,
			Slides,
			Downloads,
			Chapters
		},
		methods: {
			slideUrl(slide) {
				return this.asset.slide_path + '1920/' + slide.path;
			},
			zoomOutSlide() {
				this.$root.$emit('bv::hide::modal', 'slideModal')
			},
			filteredFormats() {
				return this.asset.asset_format.filter(f => f.format.type == 'download')
			},
			makeBr(text) {
				return text.replace(/\n/g, '<br>')
			}
		}
	}
</script>
