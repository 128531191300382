<template>
	<div id="moderator" v-if="questionsFiltered">
		<b-container class="p-0 pb-3" v-for="(questions, type, key) in questionsFiltered" :key="key">
			<div class="table-container px-2 px-md-3" v-if="questions.length">
				<b-table-simple small responsive="sm">
					<b-tbody>
						<Draggable 	v-model="questionsFiltered[type]"
									draggable=".item"
									handle=".order"
									@update="changeOrder">
							<b-tr class="item" :class="[rowClass(item), showMore === item.id ? 'active' : null]" v-for="item in questions" :key="item.id">
								<b-td class="col-auto text-dark bg-gray-lighter px-3 d-none d-md-table-cell">
									<p class="m-0 number">{{ item.id }}</p>
								</b-td>
								<b-td class="col">
									<div class="text pl-4" @click="showMore !== item.id ? showMore = item.id : showMore = null">
										<p class="m-0">{{ item.text }}</p>
										<div v-if="showMore === item.id">
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.name && item.name">
												<font-awesome-icon :icon="['fas', 'user']" fixed-width class="mr-1" /> {{ item.name }}
											</p>
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.title && item.title">
												<font-awesome-icon :icon="['fas', 'pen']" fixed-width class="mr-1" /> {{ item.title }}
											</p>
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.phone && item.phone">
												<font-awesome-icon :icon="['fas', 'phone']" fixed-width class="mr-1" /> {{ item.phone }}
											</p>
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.company && item.company">
												<font-awesome-icon :icon="['fas', 'building']" fixed-width class="mr-1" /> {{ item.company }}
											</p>
											<p class="m-0 pt-2 small font-italic" v-if="asset.asset_qa.email && item.email">
												<font-awesome-icon :icon="['fas', 'envelope']" fixed-width class="mr-1" /> {{ item.email }}
											</p>
											<p class="m-0 pt-2 small font-italic">
												<font-awesome-icon :icon="['fas', 'calendar']" fixed-width class="mr-1" /> {{ humanizeDate(item.created_at) }}
											</p>
										</div>
										<p class="m-0 pt-2 small font-italic" v-else-if="item.email">{{ item.email }}</p>
									</div>
								</b-td>
								<b-td class="col-auto d-none d-md-table-cell">
									<b-btn variant="link" class="hover-success" @click="editQuestion('answered', item)">
										<font-awesome-icon :icon="['fas', 'spinner']" spin v-if="loading && loading.type === 'answered' && loading.id === item.id" />
										<font-awesome-icon :icon="['fas', item.answered ? 'times' : 'check']" v-else />
									</b-btn>
								</b-td>
								<b-td class="col-auto d-none d-md-table-cell">
									<b-btn variant="link" class="hover-secondary" @click="editQuestion('rejected', item)">
										<font-awesome-icon :icon="['fas', 'spinner']" spin v-if="loading && loading.type === 'rejected' && loading.id === item.id" />
										<font-awesome-icon :icon="['fas', item.rejected ? 'eye-slash' : 'eye']" v-else />
									</b-btn>
								</b-td>
								<b-td class="col-auto d-none d-md-table-cell">
									<b-btn variant="link" class="hover-danger" @click="deleteQuestion(item)" :disabled="loading ? true : false">
										<font-awesome-icon :icon="['fas', 'spinner']" spin v-if="loading && loading.type === 'delete' && loading.id === item.id" />
										<font-awesome-icon :icon="['fas', 'trash']" v-else />
									</b-btn>
								</b-td>
								<b-td class="col-auto d-md-none">
									<b-dropdown variant="link" dropleft class="p-0">
										<template #button-content>
											<font-awesome-icon :icon="['fas', 'spinner']" spin v-if="loading && loading.id === item.id" />
											<font-awesome-icon :icon="['fas', 'cog']" v-else />
										</template>
										<b-dropdown-item>
											<b-btn variant="link" @click="editQuestion('answered', item)">
												<font-awesome-icon :icon="['fas', item.answered ? 'times' : 'check']" />
											</b-btn>
										</b-dropdown-item>
										<b-dropdown-item>
											<b-btn variant="link" @click="editQuestion('rejected', item)">
												<font-awesome-icon :icon="['fas', item.rejected ? 'eye-slash' : 'eye']" />
											</b-btn>
										</b-dropdown-item>
										<b-dropdown-item>
											<b-btn variant="link" @click="deleteQuestion(item)">
												<font-awesome-icon :icon="['fas', 'trash']" />
											</b-btn>
										</b-dropdown-item>
									</b-dropdown>
								</b-td>
								<b-td class="col-auto text-dark bg-gray-lighter px-4 order cursor-pointer">
									<p class="m-0"><font-awesome-icon :icon="['fas', 'bars']" /></p>
								</b-td>
							</b-tr>
						</Draggable>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-container>
		<b-modal id="addQuestionModal" ref="addQuestionModal" hide-footer hide-header centered content-class="special">
			<div class="content p-7 bg-dark-transparent" :class="blockClasses()">
				<h5 class="pb-5">{{ $t('questions.add-question') }}</h5>
				<b-form @submit.prevent="addQuestion()">
					<b-form-group class="mb-2" :class="questionForm.name ? 'filled' : null" v-if="asset.asset_qa.name">
						<label for="questionName">{{ $t('form.name') + (asset.asset_qa.name === 2 ? ' *' : '') }}</label>
						<b-form-input 	id="questionName"
										:class="inputClasses()"
										v-model="questionForm.name"
										type="text"
										:required="asset.asset_qa.name === 2 ? true : false">
						</b-form-input>
					</b-form-group>
					<b-form-group class="mb-2" :class="questionForm.title ? 'filled' : null" v-if="asset.asset_qa.title">
						<label for="questionTitle">{{ $t('form.title') + (asset.asset_qa.title === 2 ? ' *' : '') }}</label>
						<b-form-input 	id="questionTitle"
										:class="inputClasses()"
										v-model="questionForm.title"
										type="text"
										:required="asset.asset_qa.title === 2 ? true : false">
						</b-form-input>
					</b-form-group>
					<b-form-group class="mb-2" :class="questionForm.phone ? 'filled' : null" v-if="asset.asset_qa.phone">
						<label for="questionPhone">{{ $t('form.phone') + (asset.asset_qa.phone === 2 ? ' *' : '') }}</label>
						<b-form-input 	id="questionPhone"
										:class="inputClasses()"
										v-model="questionForm.phone"
										type="tel"
										:required="asset.asset_qa.phone === 2 ? true : false">
						</b-form-input>
					</b-form-group>
					<b-form-group class="mb-2" :class="questionForm.company ? 'filled' : null" v-if="asset.asset_qa.company">
						<label for="questionCompany">{{ $t('form.company') + (asset.asset_qa.company === 2 ? ' *' : '') }}</label>
						<b-form-input 	id="questionCompany"
										:class="inputClasses()"
										v-model="questionForm.company"
										type="text"
										:required="asset.asset_qa.company === 2 ? true : false">
						</b-form-input>
					</b-form-group>
					<b-form-group class="mb-2" :class="questionForm.email ? 'filled' : null" v-if="asset.asset_qa.email">
						<label for="questionEmail">{{ $t('form.email') + (asset.asset_qa.email === 2 ? ' *' : '') }}</label>
						<b-form-input 	id="questionEmail"
										:class="inputClasses()"
										v-model="questionForm.email"
										type="email"
										:required="asset.asset_qa.email === 2 ? true : false">
						</b-form-input>
					</b-form-group>
					<b-form-group class="mb-2 textarea" :class="questionForm.text ? 'filled' : null">
						<label for="questionText">{{ $t('form.question') + ' *' }}</label>
						<b-form-textarea 	id="questionText"
											:class="inputClasses()"
											v-model="questionForm.text"
											rows="3"
											max-rows="6"
											required>
						</b-form-textarea>
					</b-form-group>
					<b-row>
						<b-col class="text-right">
							<b-btn 	type="submit"
									:variant="asset.theme.skin_button_color ? asset.theme.skin_button_color : 'light'"
									:class="asset.theme.skin_button_text_color ? 'text-' + asset.theme.skin_button_text_color : 'text-dark'">
								{{ $t('form.send') }}
							</b-btn>
						</b-col>
					</b-row>
				</b-form>
			</div>
		</b-modal>
	</div>
	<Loading :inline="true" v-else />
</template>
<script>
	import Draggable from 'vuedraggable'
	import Loading from '@/components/Loading.vue'
	const moment = require('moment-timezone')
	export default {
		components: {
			Draggable,
			Loading
		},
		data() {
			return {
				loading: null,
				showMore: null,
				questionForm: {}
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			questionsFiltered() {
				if(!this.$store.getters.questions) return null
				let array =  {
					new: [], 
					answered: [],
					rejected: []
				}
				const questions_array = this.$store.getters.questions.moderator
				questions_array.forEach(question => {
					if(question.answered) {
						array.answered.push(question)
					} else if(question.rejected) {
						array.rejected.push(question)
					} else {
						array.new.push(question)
					}
				})
				return array
			},
			questionPassword() {
				if (this.$store.getters.questionPassword) return this.$store.getters.questionPassword
				if (sessionStorage.getItem('questionPassword')) return sessionStorage.getItem('questionPassword')
				if (this.$route.query.moderator) return this.$route.query.moderator
				return null
			}
		},
		methods: {
			addQuestion() {
				this.questionForm.token = this.$route.query.token
				this.questionForm.password = this.$route.query.password
				this.$store.dispatch('postQuestion', { slug: this.asset.asset_category_key, data: this.questionForm })
				.then(() => {
					this.questionForm.text = null
					this.$refs['addQuestionModal'].hide()
				})
				.catch(() => {
					this.$toasted.error(this.$t('questions.error-add'), { position: 'bottom-right' })
				})
			},
			editQuestion(field, item) {
				this.loading = { id: item.id, type: field }
				if(field) {
					item[field] = !item[field]
					if(field === 'rejected') {
						item['answered'] = false
					} else {
						item['rejected'] = false
					}
				}
				const data = {
					slug: this.asset.asset_category_key,
					id: item.id,
					moderator_password: this.questionPassword,
					answered: item.answered,
					order: item.order,
					rejected: item.rejected,
					token: this.$route.query.token,
					password: this.$route.query.password,
				}
				this.$store.dispatch('updateQuestion', data)
				.catch(() => {
					this.$toasted.error(field.answered ? this.$t('questions.error-answer') : this.$t('questions.error-reject'), { position: 'bottom-right' })
				})
				.finally(() => {
					this.loading = null
				})
			},
			deleteQuestion(item) {
				const result = confirm(this.$t('questions.delete'))
				if(!result) return 

				this.loading = { id: item.id, type: 'delete' }
				const data = {
					slug: this.asset.asset_category_key,
					id: item.id,
					moderator_password: this.questionPassword,
					token: this.$route.query.token,
					password: this.$route.query.password,
				}
				this.$store.dispatch('deleteQuestion', data)
				.catch(() => {
					this.$toasted.error(this.$t('questions.error-delete'), { position: 'bottom-right' })
				})
				.finally(() => {
					this.loading = false
				})
				
			},
			rowClass(item) {
				if(item.rejected) return 'questions-rejected' 
				if(item.answered) return 'questions-answered'
				return null
			},
			humanizeDate(date) {
				const utcDate = this.$moment(date).parseZone()
				const tz = moment.tz.guess(true)
				const tzDate = this.$moment(utcDate).tz(tz).format()
				return this.$moment(tzDate).calendar(null, {
					lastDay:  '['+this.$t('questions.yesterday')+']' +' '+ 'HH:mm',
					sameDay:  'HH:mm',
					lastWeek : 'Do MMM HH:mm',
					nextWeek : 'Do MMM HH:mm',
					sameElse: function() {
						return 'Do MMM HH:mm'
					}
				})
			},
			changeOrder(e) {
				let item = e.item._underlying_vm_
					item.order = e.newIndex
				this.editQuestion(null, item)
			},
			blockClasses() {
				return [
					this.asset.text_color ? 'text-'+this.asset.text_color : 'text-light'
				]
			},
			inputClasses() {
				return [
					this.asset.theme.skin_input_background_color ? 'bg-'+this.asset.theme.skin_input_background_color : 'bg-white',
					this.asset.theme.skin_input_border_color ? 'border-'+this.asset.theme.skin_input_border_color : 'border-light',
					this.asset.theme.skin_input_text_color ? 'text-'+this.asset.theme.skin_input_text_color : 'text-dark'
				]
			}
		}
	}
</script>
