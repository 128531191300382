var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.asset
    ? _c(
        "section",
        {
          staticClass: "d-flex",
          class:
            !this.isStreamSelected || this.isPrePostLive
              ? "justify-content-center bg-cover"
              : null,
          style:
            (_vm.backgroundImage && !this.isStreamSelected) ||
            (_vm.backgroundImage && this.isPrePostLive)
              ? _vm.backgroundImage
              : null,
          attrs: { id: "asset" },
        },
        [
          _c(
            "div",
            {
              class:
                _vm.channel.skin_background_header === "transparent" ||
                _vm.channel.skin_background_header ===
                  _vm.channel.background_color
                  ? "pt-md-3"
                  : "pt-4 pt-md-9",
            },
            [
              _vm.backgroundVideo
                ? _c(
                    "video",
                    {
                      staticClass: "bg-video",
                      attrs: {
                        autoplay: "",
                        loop: "",
                        muted: "",
                        playsinline: "",
                        "webkit-playsinline": "",
                      },
                      domProps: { muted: true },
                    },
                    [
                      _c("source", {
                        attrs: {
                          src: _vm.backgroundVideo,
                          type: _vm.genFileType,
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              [1].includes(_vm.asset.broadcasting) && !_vm.isStreamSelected
                ? _c("Stream")
                : [0].includes(_vm.asset.broadcasting)
                ? _c("PreLive")
                : [1].includes(_vm.asset.broadcasting)
                ? _c("Live")
                : [2, 3].includes(_vm.asset.broadcasting) &&
                  _vm.asset.status_id !== 5
                ? _c("PostLive")
                : _c("OnDemand"),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }