var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "py-2 d-none d-md-block",
      class: _vm.asset.theme.use_alternate_background_color
        ? "bg-background"
        : null,
      attrs: { id: "controllers" },
    },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "p-0", attrs: { cols: "auto" } },
                [
                  _c(
                    "b-btn",
                    {
                      class: _vm.viewSize === "video" ? "active" : null,
                      attrs: {
                        title:
                          _vm.viewSize === "video"
                            ? _vm.$t("controllers.reduce-video")
                            : _vm.$t("controllers.enlarge-video"),
                        size: "sm",
                        variant: "link-icon",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setView("video")
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "video"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "p-0 d-none d-xl-block",
                  attrs: { cols: "auto" },
                },
                [
                  _c(
                    "b-btn",
                    {
                      class: _vm.viewSize === "expand" ? "active" : null,
                      attrs: {
                        title:
                          _vm.viewSize === "expand"
                            ? _vm.$t("controllers.reduce-both")
                            : _vm.$t("controllers.enlarge-both"),
                        size: "sm",
                        variant: "link-icon",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setView("expand")
                        },
                      },
                    },
                    [
                      _vm.viewSize === "expand"
                        ? _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "compress"] },
                          })
                        : _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "expand"] },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "p-0", attrs: { cols: "auto" } },
                [
                  _c(
                    "b-btn",
                    {
                      class: _vm.viewSize === "slide" ? "active" : null,
                      attrs: {
                        title:
                          _vm.viewSize === "slide"
                            ? _vm.$t("controllers.reduce-slide")
                            : _vm.$t("controllers.enlarge-slide"),
                        size: "sm",
                        variant: "link-icon",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setView("slide")
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "image"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }