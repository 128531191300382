var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "login" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "pr-xl-10 pb-7 pb-lg-0",
                  attrs: { cols: "12", lg: "5" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "block bg-dark-transparent",
                      class: _vm.blockClasses(),
                    },
                    [
                      _c("h5", { staticClass: "mb-3" }, [
                        _vm._v(_vm._s(_vm.$t("login.title"))),
                      ]),
                      _c("p", { staticClass: "lead m-0 pt-3" }, [
                        _vm._v(_vm._s(_vm.$t("login.text")) + "."),
                      ]),
                      _c(
                        "b-form",
                        {
                          staticClass: "pt-5",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.login.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            { class: _vm.password ? "filled" : null },
                            [
                              _c("label", { attrs: { for: "loginPassword" } }, [
                                _vm._v(_vm._s(_vm.$t("form.password") + " *")),
                              ]),
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "loginPassword",
                                  type: "password",
                                  name: "password",
                                  autocomplete: "password",
                                  required: "true",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.errorText
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pb-3",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          attrs: {
                                            variant: "danger",
                                            show: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "m-0 small" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errorText === "noval"
                                                    ? _vm.$t(
                                                        "form.error-login-missing"
                                                      )
                                                    : _vm.$t(
                                                        "form.error-login-wrong"
                                                      )
                                                ) + "."
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      class: _vm.asset.theme
                                        .skin_button_text_color
                                        ? "text-" +
                                          _vm.asset.theme.skin_button_text_color
                                        : null,
                                      attrs: {
                                        variant: _vm.asset.theme
                                          .skin_button_color
                                          ? _vm.asset.theme.skin_button_color
                                          : "primary",
                                        type: "submit",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("form.confirm")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }