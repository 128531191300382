var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "align-center", attrs: { id: "category" } },
    [
      _c("b-container", { staticClass: "py-5 text-center" }, [
        _c("h1", [_vm._v("Category page coming soon...")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }