<template>
	<div id="login">
		<b-container>
			<b-row>
				<b-col cols="12" lg="5" class="pr-xl-10 pb-7 pb-lg-0">
					<div class="block bg-dark-transparent" :class="blockClasses()">
						<h5 class="mb-3">{{ $t('login.title') }}</h5>
						<p class="lead m-0 pt-3">{{ $t('login.text') }}.</p>
						<b-form class="pt-5" @submit.prevent="login">
							<b-form-group :class="password ? 'filled' : null">
								<label for="loginPassword">{{ $t('form.password') + ' *' }}</label>
								<b-form-input 	id="loginPassword"
												:class="inputClasses()"
												type="password"
												name="password"
												autocomplete="password"
												required="true"
												v-model="password" />
							</b-form-group>
							<b-row v-if="errorText">
								<b-col cols="12" class="pb-3">
									<b-alert variant="danger" show>
										<p class="m-0 small">{{ errorText === 'noval' ? $t('form.error-login-missing') : $t('form.error-login-wrong') }}.</p>
									</b-alert>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="12" class="text-right">
									<b-btn 	:variant="asset.theme.skin_button_color ? asset.theme.skin_button_color : 'primary'"
											:class="asset.theme.skin_button_text_color ? 'text-' + asset.theme.skin_button_text_color : null"
											type="submit">
										{{ $t('form.confirm') }}
									</b-btn>
								</b-col>
							</b-row>
						</b-form>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				password: null,
				errorText: null
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		methods: {
			login() {
				if(!this.password) {
					this.errorText = 'noval'
					return
				}
				this.$store.dispatch('getQuestions', { slug: this.asset.asset_category_key, moderator_password: this.password, password: this.$route.query.password, token: this.$route.query.token })
				.then(() => {
					this.$store.dispatch('setQuestionPassword', this.password)
				})
				.catch(() => {
					this.errorText = 'noauth'
					this.$store.dispatch('setQuestionPassword', null)
				})
			},
			blockClasses() {
				return [
					this.asset.theme.skin_block_background_color ? 'bg-'+this.asset.theme.skin_block_background_color : 'bg-light',
					this.asset.theme.skin_block_text_color ? 'text-'+this.asset.theme.skin_block_text_color : 'text-dark'
				]
			},
			inputClasses() {
				return [
					this.asset.theme.skin_input_background_color ? 'bg-'+this.asset.theme.skin_input_background_color : 'bg-white',
					this.asset.theme.skin_input_border_color ? 'border-'+this.asset.theme.skin_input_border_color : 'border-light',
					this.asset.theme.skin_input_text_color ? 'text-'+this.asset.theme.skin_input_text_color : 'text-dark',
					this.errorText && this.errorText === 'noval' ? 'error' : null
				]
			}
		}
	}
</script>
