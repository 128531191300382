<template>
	<div id="chapters" class="accordion" role="tablist">
		<b-card class="mb-7" no-body :class="[asset.theme.skin_block_background_color ? 'bg-'+asset.theme.skin_block_background_color : 'bg-light', asset.theme.skin_block_text_color ? 'text-'+asset.theme.skin_block_text_color : 'text-dark']">
			<b-card-header header-tag="div" role="tab">
				<p class="m-0 lead">{{ $t('chapters.title') }}</p>
			</b-card-header>
			<b-collapse id="chaptersList" role="tabpanel" visible>
				<b-list-group>
					<b-list-group-item 	:class="activeChapter() === chapter.id ? 'active' : null"
										@click="seekToTime(chapter.timecode)"
										v-for="(chapter, index) in asset.chapters"
										:key="index">
						<b-btn block variant="link">
							<font-awesome-icon :icon="['fas', 'play']" size="xs" />
							<span class="small" v-html="chapter.label"></span>
						</b-btn>
					</b-list-group-item>
				</b-list-group>
			</b-collapse>
		</b-card>
	</div>
</template>
<script>
	const Color = require('color')
	export default {
		data() {
			return {
				playerTime: 0
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			currentTime() {
				return this.$store.getters.currentTime
			}
		},
		methods: {
			seekToTime(time) {
				let data = { currentTime: time }
				let frame = document.querySelector('iframe');
				frame.contentWindow.postMessage(data, '*')
			},
			convertTimeToSeconds(time) {
				const a = time.split(':')
				const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
				return seconds
			},
			activeChapter() {
				let id
				const chapters = this.asset.chapters
				chapters.forEach(chapter => {
					const start = this.convertTimeToSeconds(chapter.start)
					if(start <= this.playerTime) {
						id = chapter.id
					}
				})
				return id
			},
			isDark() {
				return Color('#'+this.asset.theme.skin_color_text).isDark()
			}
		},
		watch: {
			currentTime(data) {
				this.playerTime = data
			}
		}
	}
</script>
