var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      class: _vm.asset.theme.use_alternate_background_color
        ? "bg-background pb-5"
        : null,
      attrs: { id: "slides" },
    },
    [
      _c(
        "b-container",
        { staticClass: "position-relative" },
        [
          _c(
            "div",
            {
              ref: "slidesContainer",
              style: { height: _vm.slideGutter * 4 + _vm.slideHeight + "px" },
              attrs: { id: "slidesContainer" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "slides",
                  style: {
                    width: _vm.totalContainerWidth + "px",
                    paddingTop: _vm.slideGutter * 2 + "px",
                    paddingBottom: _vm.slideGutter * 2 + "px",
                  },
                },
                _vm._l(_vm.slides, function (slide, key) {
                  return _c("div", {
                    key: key,
                    staticClass: "slide bg-cover bg-scroll",
                    class: [
                      _vm.currentSlide && _vm.currentSlide.id === slide.id
                        ? "active"
                        : "",
                      _vm.live ? "cursor-zoom-in" : "cursor-pointer",
                    ],
                    style: {
                      backgroundImage: "url(" + _vm.slideUrl(slide) + ")",
                      width: _vm.slideWidth + "px",
                      height: _vm.slideHeight + "px",
                      marginRight:
                        key + 1 !== _vm.slides.length
                          ? _vm.slideGutter + "px"
                          : "",
                    },
                    attrs: {
                      "data-key": key,
                      "data-id": slide.id,
                      "data-slideid": slide.slide_id,
                    },
                    on: {
                      click: function ($event) {
                        _vm.live
                          ? _vm.zoomSlide(slide)
                          : _vm.seekToTime(slide.timecode)
                      },
                    },
                  })
                }),
                0
              ),
            ]
          ),
          _vm.slideLeft
            ? _c(
                "b-btn",
                {
                  staticClass: "scroll scroll-left",
                  style: {
                    top: _vm.slideGutter * 2 + "px",
                    bottom: _vm.slideGutter * 2 + "px",
                  },
                  attrs: { variant: "dark" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollLeft()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-left"] },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.slideRight
            ? _c(
                "b-btn",
                {
                  staticClass: "scroll scroll-right",
                  style: {
                    top: _vm.slideGutter * 2 + "px",
                    bottom: _vm.slideGutter * 2 + "px",
                  },
                  attrs: { variant: "dark" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollRight()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-right"] },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }