<template>
	<div id="postlive">
		<b-container>
			<b-row align-v="center">
				<b-col cols="12" lg="5" class="pr-xl-10 pb-7 pb-lg-0">
					<div class="block bg-dark-transparent" :class="blockClasses()">
						<h5 class="mb-3">{{ $t('stream.ended') }}</h5>
						<p class="lead m-0 pt-3" v-html="makeBr(asset.postlive)" v-if="asset.postlive"></p>
					</div>
					<ask-question :live="false" v-if="postliveQa()" />
				</b-col>
				<b-col cols="12" lg="6" xl="5" class="mx-auto">
					<h5 class="mb-3 pb-3" v-html="makeBr(asset.title)" v-if="asset.title" />
					<p class="lead m-0 text-light" v-html="makeBr(asset.description)" v-if="asset.description"></p>
					<Talkers class="pt-8" />
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	import AskQuestion from '@/components/asset/AskQuestion.vue'
	import Talkers from '@/components/asset/Talkers.vue'
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		components: {
			AskQuestion,
			Talkers
		},
		methods: {
			postliveQa() {
				if(this.asset.asset_qa && this.asset.asset_qa.enabled && this.asset.asset_qa.postlive) return true
				return false
			},
			makeBr(text) {
				return text.replace(/\n/g, '<br>')
			},
			blockClasses() {
				return [
					this.asset.text_color ? 'text-'+this.asset.text_color : 'text-light'
				]
			}
		}
	}
</script>
