<template>
	<div id="chat" class="sidebar" :class="sidebar === 'chat' ? 'visible' : null">
		<iframe :src="asset.chat_url"></iframe>
    </div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			sidebar() {
				return this.$store.getters.sidebar
			}
		}
	}
</script>
